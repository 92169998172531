import api from "../common/apis";
import NProgress from "nprogress";
export const GET_ALL_REVENUES = async (req) => {
  try {
    NProgress.start();
    const res = await api.post(`/sellers/statistics/statistic-revenues`, {
      startTime: req.startTime,
      endTime: req.endTime,
    });
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const GET_ALL_REVENUES_Invite = async (req) => {
  try {
    NProgress.start();
    const res = await api.post(
      `/sellers/statistics/statistic-revenues-invite`,
      {
        startTime: req.startTime,
        endTime: req.endTime,
      }
    );
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
export const CHECK_UPGRADE = async (req) => {
  try {
    NProgress.start();
    const res = await api.get(`/sellers/check-upgrade-status`);
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
export const Create_send_payment = async (req) => {
  try {
    NProgress.start();
    const respod = await api.post(
      `/sellers/payments/send-transaction-upgrade`,
      req,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          // Thêm các header khác nếu cần
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    return respod.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const Create_send_payment_Order = async (req) => {
  try {
    NProgress.start();
    const respod = await api.post(`/sellers/payments/send-transaction`, req, {
      headers: {
        "Content-Type": "multipart/form-data",
        // Thêm các header khác nếu cần
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return respod.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const GET_ALL_REQUEST_UPGRADE = async (req) => {
  try {
    NProgress.start();
    const res = await api.get(`/sellers/get-all-upgrade`);
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
export const GET_ALL_PACKAGE = async (req) => {
  try {
    NProgress.start();
    const res = await api.get(`/sellers/get-all-package`);
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const GET_INFOR = async (req) => {
  try {
    NProgress.start();
    const res = await api.get(`/auth/get-me`);
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
export const Get_statistics_Infor = async (req) => {
  try {
    NProgress.start();
    const res = await api.get(
      `/sellers/statistics/count-customer-seller-purchase`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const SELLER_UPGRADE_ACCOUNT = async (req) => {
  try {
    NProgress.start();
    const res = await api.post(`/sellers/upgrade`, req);
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
export const CREATE_BANK_ACCOUNT = async (req) => {
  try {
    NProgress.start();
    const res = await api.post(`/auth/add-bank-info`, req);
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const UPDATE_BANK_ACCOUNT = async (req) => {
  try {
    NProgress.start();
    const respod = await api.put(`/auth/update-bank-info`, req, {
      headers: {
        "Content-Type": "application/json",
        // Thêm các header khác nếu cần
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return respod.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
export const CHANGE_PASS = async (req) => {
  try {
    NProgress.start();
    const respod = await api.put(`/auth/change-password`, req, {
      headers: {
        "Content-Type": "application/json",
        // Thêm các header khác nếu cần
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return respod.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      if (error.response.data?.errors) {
        return { ...error.response.data };
      } else {
        return { ...error.response.data, trigger: true };
      }
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
export const UPDATE_INFOR = async (req) => {
  try {
    NProgress.start();
    const respod = await api.put(`/auth/update-me`, req, {
      headers: {
        "Content-Type": "application/json",
        // Thêm các header khác nếu cần
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return respod.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return { ...error.response.data, type: "stop" };
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
export const CHANGE_AVATAR = async (req) => {
  try {
    NProgress.start();
    const respod = await api.post(`/auth/upload-avatar`, req, {
      headers: {
        "Content-Type": "multipart/form-data",
        // Thêm các header khác nếu cần
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return respod.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
