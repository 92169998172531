import React, { useState, useEffect } from "react";
import menu from "../Pictures/icon-menu.svg";
import "./style.css";
import { Badge, Box, Button, IconButton, Typography } from "@mui/material";
import Cart from "./Cart";
import MobileLinksDrawer from "./MobileLinksDrawer";
import { useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import LOGO from "../../../assets/image/LOGI_TIKPII.png.jpg";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import UpgradeOutlinedIcon from "@mui/icons-material/UpgradeOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { LOG_OUT } from "../../../services/handleAccount/handleAccount";
import { useMutation } from "@tanstack/react-query";

const Navbar = ({ stateCart, stateAccount, activeIndex }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  const MutationLogout = useMutation({
    mutationKey: ["log_out"],
    mutationFn: (body) => {
      return LOG_OUT(body);
    },
  });
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0 || window.pageYOffset <= 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let nav = useNavigate();
  const [showCart, setShowCart] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = (val) => {
    setOpen(val);
  };

  return (
    <>
      <header
        style={{ height: "64px" }}
        className={`sticky-header ${isScrolled ? "scrolled" : ""}`}
      >
        <nav
          className="PaddingLRForNav"
          style={{ paddingLeft: "10%", paddingRight: "10%" }}
        >
          <section className="left">
            <div className="imgs">
              <img src={LOGO} alt="logo" width={"100"} />
            </div>
          </section>
          <div className="right">
            <IconButton
              disableRipple
              onClick={() => {
                // setShowCart(!showCart);
                nav("/cart");
              }}
            >
              <Badge
                invisible={
                  stateCart?.purchase_items
                    ? stateCart?.purchase_items.length === 0
                    : ""
                }
                badgeContent={
                  stateCart?.purchase_items
                    ? stateCart?.purchase_items.length
                    : 0
                }
                variant="standard"
                sx={{
                  color: "#fff",
                  fontFamily: "Kumbh sans",
                  fontWeight: 700,
                  "& .css-fvc8ir-MuiBadge-badge ": {
                    fontFamily: "kumbh sans",
                    fontWeight: 700,
                  },
                }}
              >
                <svg width="22" height="20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M20.925 3.641H3.863L3.61.816A.896.896 0 0 0 2.717 0H.897a.896.896 0 1 0 0 1.792h1l1.031 11.483c.073.828.52 1.726 1.291 2.336C2.83 17.385 4.099 20 6.359 20c1.875 0 3.197-1.87 2.554-3.642h4.905c-.642 1.77.677 3.642 2.555 3.642a2.72 2.72 0 0 0 2.717-2.717 2.72 2.72 0 0 0-2.717-2.717H6.365c-.681 0-1.274-.41-1.53-1.009l14.321-.842a.896.896 0 0 0 .817-.677l1.821-7.283a.897.897 0 0 0-.87-1.114ZM6.358 18.208a.926.926 0 0 1 0-1.85.926.926 0 0 1 0 1.85Zm10.015 0a.926.926 0 0 1 0-1.85.926.926 0 0 1 0 1.85Zm2.021-7.243-13.8.81-.57-6.341h15.753l-1.383 5.53Z"
                    fill="#69707D"
                    fillRule="nonzero"
                  />
                </svg>
              </Badge>
            </IconButton>

            <div class="btn-group">
              <SettingsIcon
                id="triggerId"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                color="#898989"
              ></SettingsIcon>

              <div
                class="dropdown-menu dropdown-menu-start"
                aria-labelledby="triggerId"
                style={{ padding: "0px" }}
              >
                <a
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  href="/#/infor/password"
                  class="dropdown-item"
                >
                  <LockOutlinedIcon
                    sx={{ marginRight: "5px", fontSize: "16px" }}
                  ></LockOutlinedIcon>{" "}
                  <Typography
                    fontSize={"14px"}
                    sx={{ padding: "5px", marginTop: "2px" }}
                  >
                    Đổi mật khẩu
                  </Typography>
                </a>
                {stateAccount?.upgrade ? (
                  <a class="dropdown-item disabled">
                    Tài khoản {stateAccount?.upgrade?.package?.name}
                  </a>
                ) : (
                  ""
                )}

                <a
                  class="dropdown-item"
                  style={{
                    cursor: "pointer",
                    color: "red",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    MutationLogout.mutate(
                      {},
                      {
                        onSuccess: (data) => {
                          localStorage.clear();
                          nav("/login");
                        },
                        onError: (err) => {
                          localStorage.clear();
                          nav("/login");
                        },
                      }
                    );
                  }}
                >
                  <LogoutOutlinedIcon
                    sx={{ marginRight: "5px", fontSize: "16px" }}
                  ></LogoutOutlinedIcon>{" "}
                  <Typography
                    fontSize={"14px"}
                    sx={{ padding: "5px", marginTop: "2px" }}
                  >
                    {" "}
                    Đăng xuất
                  </Typography>
                </a>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <Box
        justifyContent={"space-evenly"}
        sx={{ borderWidth: "1px", borderColor: "rgb(239 239 239)" }}
        padding={"15px 20px"}
        className="row PaddingLRForNav"
        gap={1}
      >
        <Box
          sx={{
            textAlign: "center",
            borderWidth: "1px ",

            paddingTop: "2px",
            paddingBottom: "2px",
            borderRadius: "20px",
            fontSize: "14px",

            flex: 0.8,
            cursor: "pointer",

            borderColor: activeIndex === 0 ? "blue" : "444A40",
            color: activeIndex === 0 ? "blue" : "444A40",
          }}
          onClick={() => {
            nav("/home");
          }}
        >
          Bão đơn
        </Box>
        <Box
          sx={{
            textAlign: "center",
            borderWidth: "1px",
            paddingTop: "2px",
            paddingBottom: "2px",
            borderRadius: "20px",
            fontSize: "14px",
            flex: 0.8,
            cursor: "pointer",

            borderColor: activeIndex === 1 ? "blue" : "444A40",
            color: activeIndex === 1 ? "blue" : "444A40",
          }}
          onClick={() => {
            nav("/rank");
          }}
        >
          Xếp hạng
        </Box>{" "}
        <Box
          sx={{
            textAlign: "center",
            borderWidth: "1px",
            paddingTop: "2px",
            paddingBottom: "2px",
            borderRadius: "20px",
            color: "#444A40",
            fontSize: "14px",
            flex: 0.8,
            cursor: "pointer",
            borderColor: activeIndex === 2 ? "blue" : "444A40",
            color: activeIndex === 2 ? "blue" : "444A40",
          }}
          // onClick={() => {
          //   handleItemClick(2);
          // }}
        >
          Khuyến mãi
        </Box>
      </Box>
    </>
  );
};

export default Navbar;
