import React, { useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import styled from "styled-components";

const StyledSelect = styled.select`
  border: none;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 8px;
  background-color: #f3f6f9;
  color: #333;
  width: 100%;
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
  }
`;

const StyledOption = styled.option`
  padding: 8px;
  background-color: #fff;
  color: #333;
  font-size: 16px;

  &:hover {
    background-color: #f3f6f9;
  }
`;
export const UnstyledSelectIntroduction = ({
  stateStatus,
  setStateStatus,
  handleRefreshData,
  setStatePage,
}) => {
  const handleChange = (event) => {
    setStateStatus(event.target.value);
    setStatePage(1);
  };
  return (
    <FormControl fullWidth>
      <StyledSelect
        labelId="dropdown-label"
        label="Chọn một mục"
        onChange={handleChange}
        defaultValue={stateStatus}
      >
        <StyledOption value={""}>Lấy tất cả sản phẩm</StyledOption>
        <StyledOption
          value={parseInt(process.env.REACT_APP_WAIT_FOR_CONFIRMATION)}
        >
          Đang chờ xác nhận
        </StyledOption>
        <StyledOption value={parseInt(process.env.REACT_APP_WAIT_FOR_GETTING)}>
          Đang được lấy hàng
        </StyledOption>
        <StyledOption value={parseInt(process.env.REACT_APP_IN_PROGRESS)}>
          Đang được vận chuyển
        </StyledOption>
        <StyledOption value={parseInt(process.env.REACT_APP_DELIVERED)}>
          Đã giao
        </StyledOption>
        <StyledOption value={parseInt(process.env.REACT_APP_CANCELLED)}>
          Đã huỷ
        </StyledOption>
      </StyledSelect>
    </FormControl>
  );
};

export const SelectOldNewOrder = ({
  setStateSortDate,
  stateSortDate,
  setStatePage,
}) => {
  const handleChange = (event) => {
    setStateSortDate(event.target.value);
    setStatePage(1);
  };
  return (
    <FormControl fullWidth>
      <StyledSelect
        labelId="dropdown-label"
        label="Chọn một mục"
        onChange={handleChange}
        defaultValue={stateSortDate}
      >
        <StyledOption value={"latest"}>Mới nhất</StyledOption>
        <StyledOption value={"oldest"}>Cũ nhất</StyledOption>
      </StyledSelect>
    </FormControl>
  );
};
