import styled from "styled-components";
import AccountBox from "../AccountBox/index";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
const Auth = ({ check }) => {
  let Nav = useNavigate();

  const AppContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `;
  useEffect(() => {
    window.scrollTo(0, 0);


    if (!localStorage.getItem("token")) {
      return Nav("/");
    } else {
      const decodedToken = jwtDecode(localStorage.getItem("token"));
      const currentTime = Date.now() / 1000;
      if (
        decodedToken?.roles != parseInt(process.env.REACT_APP_ROLE_SELLER) ||
        currentTime > decodedToken.exp
      ) {
        localStorage.clear();
      } else {
        return Nav("/product");

      }
    }


  }, []);

  return (
    <>
      <AppContainer>
        <AccountBox />
      </AppContainer>
    </>
  );
};

export default Auth;
