import { Box, Skeleton, Stack, Typography } from "@mui/material";
import {
  GET_ALL_REVENUES,
  GET_ALL_REVENUES_Invite,
} from "../../services/handleInfor/handleInfor";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import DatePicker from "react-date-picker";
import SearchIcon from "@mui/icons-material/Search";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import { CaseDatetimeToYYYYMMDDHHMMSS } from "../../component/function";
import { useNavigate } from "react-router-dom";
import LinkIcon from "@mui/icons-material/Link";
import Back from "../../component/Back";
import LabelIcon from "@mui/icons-material/Label";
import Person4Icon from "@mui/icons-material/Person4";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import "./style.css";
const Revenue = () => {
  let nav = useNavigate();

  const today = new Date();
  // Ngày bắt đầu của tháng
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  // Ngày kết thúc của tháng
  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Tháng bắt đầu từ 0, cần +1
  const formattedDateF = `${year}-${month}-${String(
    startOfMonth.getDate()
  ).padStart(2, "0")}`;
  const formattedDateT = `${year}-${month}-${String(
    endOfMonth.getDate()
  ).padStart(2, "0")}`;
  const [dateF, onChangeF] = useState(formattedDateF);
  const [dateT, onChangeT] = useState(formattedDateT);
  const [stateDataRevenue, setStateDataRevenue] = useState({});
  const [stateDataRevenue_Invite, setStateDataRevenue_Invite] = useState({});
  const [IsLoading, setIsloading] = useState(false);
  const [IsLoadingInvite, setIsloadingInvite] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };
  const handle_get_Revenue = useMutation({
    mutationKey: ["STATISTIC_ORDER"],
    mutationFn: (body) => {
      return GET_ALL_REVENUES(body);
    },
  });
  const handle_get_Revenue_Invite = useMutation({
    mutationKey: ["STATISTIC_INVITE"],
    mutationFn: (body) => {
      return GET_ALL_REVENUES_Invite(body);
    },
  });
  const handleSearch = () => {
    setIsloading(true);
    setIsloadingInvite(true);
    let Form = {
      startTime: dateF,
      endTime: dateT,
    };
    handle_get_Revenue.mutate(Form, {
      onSuccess: (data) => {
        if (data?.data?.data) {
          setStateDataRevenue(data?.data?.data);
          setIsloading(false);
        }
      },
      onError: (err) => {
        setIsloading(false);
      },
    });

    handle_get_Revenue_Invite.mutate(Form, {
      onSuccess: (data) => {
        if (data?.data?.data) {
          setStateDataRevenue_Invite(data?.data?.data);
          setIsloadingInvite(false);
        }
      },
      onError: (err) => {
        setIsloadingInvite(false);
      },
    });
  };
  return (
    <>
      <Box className="PaddingLRForCustomer">
        <Back content={"Trở về"}></Back>
        <Box
          display={"flex"}
          width={"100%"}
          alignItems={"center"}
          p={3}
          justifyContent={"space-between"}
          borderBottom={"solid 5px #eeedee"}
        >
          <Box>
            <Typography
              // variant="h7"
              sx={{ color: "#444A4D" }}
              fontWeight={"600"}
            >
              Ngày bắt đầu
            </Typography>
            <DatePicker
              clearIcon={null}
              format="y-MM-dd"
              onChange={(e) => {
                let year = JSON.stringify(e)
                  .replace('"', "")
                  .split("T")[0]
                  .split("-")[0];
                let Month = JSON.stringify(e)
                  .replace('"', "")
                  .split("T")[0]
                  .split("-")[1];
                let day = parseInt(
                  parseInt(
                    JSON.stringify(e)
                      .replace('"', "")
                      .split("T")[0]
                      .split("-")[2]
                  )
                )
                  .toString()
                  .padStart(2, "0");

                let Formdate = year + "-" + Month + "-" + day;
                onChangeF(Formdate);
              }}
              value={dateF || today}
            />
          </Box>
          <Box>
            <Typography sx={{ color: "#444A4D" }} fontWeight={"600"}>
              Ngày Kết thúc
            </Typography>
            <DatePicker
              clearIcon={null}
              format="y-MM-dd"
              onChange={(e) => {
                let year = JSON.stringify(e)
                  .replace('"', "")
                  .split("T")[0]
                  .split("-")[0];
                let Month = JSON.stringify(e)
                  .replace('"', "")
                  .split("T")[0]
                  .split("-")[1];
                let day = parseInt(
                  parseInt(
                    JSON.stringify(e)
                      .replace('"', "")
                      .split("T")[0]
                      .split("-")[2]
                  )
                )
                  .toString()
                  .padStart(2, "0");

                let Formdate = year + "-" + Month + "-" + day;
                onChangeT(Formdate);
              }}
              value={dateT || today}
            />
          </Box>
          <Box>
            <button onClick={handleSearch} className="btn btn-info">
              <SearchIcon
                sx={{ color: "white" }}
                fontSize="medium"
              ></SearchIcon>
            </button>
          </Box>
        </Box>

        <Box p={3} borderBottom={"solid 5px #eeedee"}>
          <Typography alignItems={"center"} display={"flex"}>
            {/* <Typography sx={{ color: "#444A4D" }} fontWeight={"600"}>
              {" "}
              Doanh thu trong khoảng:
            </Typography> */}
            <CalendarMonthRoundedIcon
              sx={{ color: "#393E40" }}
            ></CalendarMonthRoundedIcon>
            <Typography
              ml={1}
              sx={{ color: "#444A4D" }}
              fontWeight={"600"}
              fontSize={"1rem"}
            >
              {" "}
              {dateF ? dateF : ""}
              <ArrowRightAltIcon
                sx={{ color: "black" }}
              ></ArrowRightAltIcon>{" "}
              {dateT ? dateT : ""}
            </Typography>
          </Typography>
          <Box alignItems={"center"} display={"flex"}>
            <Typography alignItems={"center"} display={"flex"}>
              <LocalAtmIcon fontSize="small" color="success"></LocalAtmIcon>
              <Typography ml={1} p={1} fontSize={"1rem"} color={"#5C6366"}>
                Lợi nhuận đơn hàng:
              </Typography>
            </Typography>
            <Typography sx={{ color: "#66bb6a" }} fontSize={"1rem"}>
              {stateDataRevenue?.totalMoney
                ? stateDataRevenue &&
                  parseInt(stateDataRevenue?.totalMoney).toLocaleString("en-us")
                : 0}{" "}
              VNĐ
            </Typography>
          </Box>

          <Box alignItems={"center"} display={"flex"}>
            <Typography alignItems={"center"} display={"flex"}>
              <Inventory2Icon fontSize="small" color="success"></Inventory2Icon>
              <Typography ml={1} p={1} fontSize={"1rem"} color={"#5C6366"}>
                Tổng đơn hàng :
              </Typography>
            </Typography>
            <Typography sx={{ color: "#66bb6a" }} fontSize={"1rem"}>
              {stateDataRevenue?.purchases
                ? parseInt(stateDataRevenue?.purchases.length)
                : 0}
            </Typography>
          </Box>

          <Box alignItems={"center"} display={"flex"}>
            <Typography alignItems={"center"} display={"flex"}>
              <LabelIcon fontSize="small" color="success"></LabelIcon>
              <Typography ml={1} p={1} fontSize={"1rem"} color={"#5C6366"}>
                Tổng số point :
              </Typography>
            </Typography>
            <Typography sx={{ color: "#66bb6a" }} fontSize={"1rem"}>
              {stateDataRevenue?.totalPoint
                ? parseInt(stateDataRevenue?.totalPoint).toLocaleString("en-us")
                : 0}
            </Typography>
          </Box>

          <hr></hr>
          <Box alignItems={"center"} display={"flex"}>
            <Typography alignItems={"center"} display={"flex"}>
              <LocalAtmIcon fontSize="small" color="success"></LocalAtmIcon>
              <Typography ml={1} p={1} fontSize={"1rem"} color={"#5C6366"}>
                Lợi nhuận đại lý :
              </Typography>
            </Typography>
            <Typography sx={{ color: "#66bb6a" }} fontSize={"1rem"}>
              {stateDataRevenue_Invite?.totalMoney
                ? parseInt(stateDataRevenue_Invite?.totalMoney).toLocaleString(
                    "en-us"
                  )
                : 0}{" "}
              VNĐ
            </Typography>
          </Box>
          <Box alignItems={"center"} display={"flex"}>
            <Typography alignItems={"center"} display={"flex"}>
              <Person4Icon fontSize="small" color="success"></Person4Icon>
              <Typography ml={1} p={1} fontSize={"1rem"} color={"#5C6366"}>
                Tổng đại lý tham gia :
              </Typography>
            </Typography>
            <Typography sx={{ color: "#66bb6a" }} fontSize={"1rem"}>
              {stateDataRevenue_Invite?.upgrade
                ? parseInt(stateDataRevenue_Invite?.upgrade.length)
                : 0}
            </Typography>
          </Box>
        </Box>
        <Box
          p={3}
          display={"flex"}
          borderBottom={"solid 5px #eeedee"}
          justifyContent={"flex-start"}
          gap={1}
        >
          <Typography
            className={
              activeIndex === 0 ? "col-3 headerNav active" : "headerNav"
            }
            sx={{
              color: activeIndex === 0 ? "blue" : "",
              width: "200px",
              textAlign: "center",
            }}
            onClick={() => {
              handleItemClick(0);
            }}
          >
            Đơn hàng
          </Typography>
          <Typography
            className={
              activeIndex === 1 ? "col-3 headerNav active" : " headerNav"
            }
            sx={{
              color: activeIndex === 1 ? "blue" : "",
              width: "200px",
              textAlign: "center",
            }}
            onClick={() => {
              handleItemClick(1);
            }}
          >
            Tham gia
          </Typography>
        </Box>
        {activeIndex === 0 ? (
          <Box>
            {IsLoading ? (
              <Stack p={3}>
                Đang lấy dữ liệu ...
                <Skeleton variant="rounded" width={"100%"} height={200} />
              </Stack>
            ) : (
              <>
                {" "}
                <Box
                  sx={{
                    maxHeight: "50vh",
                    paddingBottom: "50px",
                    overflowY: "scroll",
                  }}
                >
                  <Typography
                    fontSize={"16px"}
                    fontWeight={600}
                    color={"#393E40"}
                    padding={"12px 12px 0px 12px"}
                  >
                    Thống kê dữ liệu đơn hàng
                  </Typography>
                  {stateDataRevenue?.purchases &&
                  stateDataRevenue?.purchases.length > 0 ? (
                    <>
                      {" "}
                      {stateDataRevenue?.purchases &&
                        stateDataRevenue?.purchases.map((item, index) => (
                          <Box style={{ backgroundColor: "white" }}>
                            <Box
                              display={"flex"}
                              padding={"12px 12px 0px 12px"}
                              justifyContent={"space-between"}
                              width={"100%"}
                              borderBottom={"solid 1px gainsboro"}
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                nav(`/orderDetails`, { state: item });
                              }}
                            >
                              <ul style={{ padding: "0px", width: "80%" }}>
                                <li>
                                  <Typography
                                    fontWeight={700}
                                    color={"#607CFB"}
                                    fontSize={".7rem"}
                                  >
                                    <LinkIcon
                                      color={"#607CFB"}
                                      fontSize="small"
                                    >
                                      {" "}
                                    </LinkIcon>{" "}
                                    Mã đơn hàng : {item?._id}
                                  </Typography>
                                </li>
                                <span style={{ color: "GrayText" }}>
                                  **Thông tin khách hàng
                                </span>
                                <li style={{ paddingBottom: "2%" }}>
                                  <Typography
                                    variant="subtitle1"
                                    fontWeight={700}
                                  >
                                    Tên: {item?.customer?.name}
                                  </Typography>
                                </li>
                                <li>
                                  <Typography>
                                    Số điện thoại: {item?.customer?.phone}
                                  </Typography>
                                </li>
                                <li>
                                  <Typography color={"#607CFB"}>
                                    Địa chỉ :{item?.customer?.address}
                                  </Typography>
                                </li>
                                <span style={{ color: "GrayText" }}>
                                  **Thống kê
                                </span>

                                <br></br>
                                <span>
                                  Số lượng sản phẩm{" "}
                                  {item?.purchase_items
                                    ? item?.purchase_items.length
                                    : 0}
                                </span>
                                <li>
                                  <Typography>
                                    Tổng giá trị đơn hàng :{" "}
                                    {parseInt(
                                      item?.purchase_total_price
                                    ).toLocaleString("en-us")}{" "}
                                    đ
                                  </Typography>
                                </li>
                                <li>
                                  <Typography>
                                    Lợi nhuận thu về :{" "}
                                    {parseInt(
                                      item?.purchase_total_profit_for_seller
                                    ).toLocaleString("en-us")}{" "}
                                    đ
                                  </Typography>
                                </li>
                                <span style={{ color: "GrayText" }}>
                                  **Thời điểm lên đơn :{" "}
                                  {CaseDatetimeToYYYYMMDDHHMMSS(
                                    item?.created_at
                                  )}
                                </span>
                              </ul>
                            </Box>

                            <Box borderBottom={"solid 5px #eeedee"} p={"12px"}>
                              {item?.status ===
                              parseInt(
                                process.env.REACT_APP_WAIT_FOR_CONFIRMATION
                              ) ? (
                                <Typography color={"#9b9b61"}>
                                  Tình trạng đơn : ĐANG CHỜ XÁC NHẬN
                                </Typography>
                              ) : item?.status ===
                                parseInt(
                                  process.env.REACT_APP_WAIT_FOR_GETTING
                                ) ? (
                                <Typography color={"#00BFFF"}>
                                  Tình trạng đơn : ĐANG ĐƯỢC LẤY HÀNG
                                </Typography>
                              ) : item?.status ===
                                parseInt(process.env.REACT_APP_IN_PROGRESS) ? (
                                <Typography color={"#FFA500"}>
                                  Tình trạng đơn : ĐƠN ĐANG VẬN CHUYỂN
                                </Typography>
                              ) : item?.status ===
                                parseInt(process.env.REACT_APP_DELIVERED) ? (
                                <Typography color={"#008000"}>
                                  Tình trạng đơn : ĐÃ ĐƯỢC GIAO
                                </Typography>
                              ) : (
                                <Typography color={"#FF0000"}>
                                  Tình trạng đơn : ĐÃ HUỶ
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        ))}
                    </>
                  ) : (
                    <Typography
                      fontSize={"14px"}
                      color={"GrayText"}
                      padding={"12px 12px 0px 12px"}
                    >
                      *Chưa có dữ đơn hàng nào
                    </Typography>
                  )}
                </Box>
              </>
            )}
          </Box>
        ) : (
          <Box>
            {IsLoadingInvite ? (
              <Stack p={3}>
                Đang lấy dữ liệu ...
                <Skeleton variant="rounded" width={"100%"} height={200} />
              </Stack>
            ) : (
              <>
                {" "}
                <Box
                  sx={{
                    height: "50vh",
                    paddingBottom: "50px",
                    overflowY: "scroll",
                  }}
                >
                  <Typography
                    fontSize={"16px"}
                    fontWeight={600}
                    color={"#393E40"}
                    padding={"12px 12px 0px 12px"}
                  >
                    Thống kê tài khoản nâng cấp
                  </Typography>
                  {stateDataRevenue_Invite?.upgrade &&
                  stateDataRevenue_Invite?.upgrade.length > 0 ? (
                    <>
                      {" "}
                      {stateDataRevenue_Invite?.upgrade &&
                        stateDataRevenue_Invite?.upgrade.map((item, index) => (
                          <Box style={{ backgroundColor: "white" }}>
                            <Box
                              display={"flex"}
                              padding={"12px 12px 0px 12px"}
                              justifyContent={"space-between"}
                              width={"100%"}
                              borderBottom={"solid 1px gainsboro"}
                              sx={{ cursor: "pointer" }}
                            >
                              <ul style={{ padding: "0px", width: "80%" }}>
                                <li style={{ paddingBottom: "2%" }}>
                                  <Typography
                                    variant="subtitle1"
                                    fontWeight={700}
                                  >
                                    Tên: {item?.seller_info?.name}
                                  </Typography>
                                </li>
                                <li>
                                  <Typography>
                                    Số điện thoại: {item?.seller_info?.phone}
                                  </Typography>
                                </li>
                                <li>
                                  <Typography color={"#607CFB"}>
                                    Gói nâng cấp: {item?.package_info?.name}
                                  </Typography>
                                </li>
                                <span style={{ color: "GrayText" }}>
                                  **Thời điểm thực hiện:{" "}
                                  {CaseDatetimeToYYYYMMDDHHMMSS(
                                    item?.created_at
                                  )}
                                </span>
                              </ul>
                            </Box>
                          </Box>
                        ))}
                    </>
                  ) : (
                    <Typography
                      fontSize={"14px"}
                      color={"GrayText"}
                      padding={"12px 12px 0px 12px"}
                    >
                      *Chưa có dữ liệu tài khoản nào
                    </Typography>
                  )}
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};
export default Revenue;
