import { Avatar, Box, Typography } from "@mui/material";
import Navbar from "../../component/Navbar/navbar";
import { GET_ALL_ACCOUNT } from "../../services/handleHomepage/handleHomepage";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import IMGCROWN from "../../assets/image/rank/crown.png";
import IMGBGRANK from "../../assets/image/rank/bg.jpg";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { MOCK_DATA_USER } from "../../data/data";

const Rank = ({ stateProduct, stateCart }) => {
  let sort = MOCK_DATA_USER.sort((a, b) => b.money - a.money);

  const [stateUser, setStateUser] = useState(sort);

  return (
    <>
      <Navbar
        stateProduct={stateProduct}
        stateCart={stateCart}
        activeIndex={1}
      ></Navbar>

      <Box className="AddpadingLeftProduct">
        <Box
          className="row"
          justifyContent={"center"}
          alignContent={"center"}
          height={"300px"}
          sx={{ backgroundImage: `url(${IMGBGRANK})`, objectFit: "contain" }}
        >
          {/* TOP 2 */}
          <Box
            height={"100%"}
            alignContent={"end"}
            justifyContent={"end"}
            className="col-3"
            p={0}
            display={"flex"}
            flexDirection={"column"}
          >
            <Box
              position={"relative"}
              borderRadius={"10px 10px 0px 0px"}
              bgcolor={"rgba(0, 0, 0, 0.3)"}
              height={"150px"}
            >
              <Box
                sx={{
                  position: "absolute",

                  left: "50%",
                  top: "-10%",

                  transform: "translate(-50%, -10%)",
                }}
              >
                <Avatar
                  src={stateUser[1]?.avatar}
                  sx={{
                    position: "absolute",
                    width: 56,
                    height: 56,
                    left: "50%",
                    top: "-10%",

                    transform: "translate(-50%, -10%)",
                  }}
                ></Avatar>
                <Typography
                  sx={{
                    position: "absolute",
                    width: 30,
                    height: 30,
                    left: "50%",
                    top: "120%",
                    borderRadius: "100%",
                    fontSize: "18px",
                    fontWeight: 700,
                    backgroundColor: "white",
                    textAlign: "center",
                    alignContent: "center",
                    transform: "translate(-50%, 120%)",
                    border: "solid 2px #000f80",
                  }}
                >
                  2
                </Typography>
                <Typography
                  sx={{
                    position: "absolute",
                    width: "100px",
                    height: 30,
                    left: "50%",
                    top: "220%",
                    borderRadius: "100%",
                    fontSize: "13px",
                    fontWeight: 700,
                    color: "white",
                    textAlign: "center",
                    alignContent: "center",
                    transform: "translate(-50%, 220%)",
                  }}
                >
                  {parseInt(stateUser[1]?.money).toLocaleString("en-us")}
                </Typography>
              </Box>

              <Box></Box>
            </Box>
          </Box>

          {/* TOP 1 */}
          <Box
            height={"100%"}
            alignContent={"end"}
            justifyContent={"end"}
            className="col-3"
            p={0}
            display={"flex"}
            flexDirection={"column"}
          >
            <Box
              position={"relative"}
              borderRadius={"10px 10px 0px 0px"}
              bgcolor={"rgba(0, 0, 0, 0.5)"}
              height={"170px"}
            >
              <Box
                sx={{
                  position: "absolute",

                  left: "50%",
                  top: "-10%",

                  transform: "translate(-50%, -10%)",
                }}
              >
                <Avatar
                  src={IMGCROWN}
                  sx={{
                    position: "absolute",
                    width: 66,
                    height: 66,
                    left: "50%",
                    top: "-80%",
                    zIndex: 2,
                    transform: "translate(-50%, -80%)",
                  }}
                ></Avatar>
                <Avatar
                  src={stateUser[0]?.avatar}
                  sx={{
                    position: "absolute",
                    width: 56,
                    height: 56,
                    left: "50%",
                    top: "-10%",
                    border: "solid 4px #fedf72",
                    transform: "translate(-50%, -10%)",
                  }}
                ></Avatar>
                <Typography
                  sx={{
                    position: "absolute",
                    width: 30,
                    height: 30,
                    left: "50%",
                    top: "120%",
                    borderRadius: "100%",
                    fontSize: "18px",
                    fontWeight: 700,
                    backgroundColor: "#fedf72",
                    textAlign: "center",
                    alignContent: "center",
                    transform: "translate(-50%, 120%)",
                    border: "solid 2px #000f80",
                  }}
                >
                  1
                </Typography>

                <Typography
                  sx={{
                    position: "absolute",
                    width: "100px",
                    height: 30,
                    left: "50%",
                    top: "220%",
                    borderRadius: "100%",
                    fontSize: "13px",
                    fontWeight: 700,
                    color: "white",
                    textAlign: "center",
                    alignContent: "center",
                    transform: "translate(-50%, 220%)",
                  }}
                >
                  {parseInt(stateUser[0]?.money).toLocaleString("en-us")}
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* TOP 3 */}
          <Box
            height={"100%"}
            alignContent={"end"}
            justifyContent={"end"}
            className="col-3"
            p={0}
            display={"flex"}
            flexDirection={"column"}
          >
            <Box
              position={"relative"}
              borderRadius={"10px 10px 0px 0px"}
              bgcolor={"rgba(0, 0, 0, 0.4)"}
              height={"130px"}
            >
              <Box
                sx={{
                  position: "absolute",

                  left: "50%",
                  top: "-10%",

                  transform: "translate(-50%, -10%)",
                }}
              >
                <Avatar
                  src={stateUser[2]?.avatar}
                  sx={{
                    position: "absolute",
                    width: 56,
                    height: 56,
                    left: "50%",
                    top: "-10%",
                    border: "solid 4px #fa541b",
                    transform: "translate(-50%, -10%)",
                  }}
                ></Avatar>
                <Typography
                  sx={{
                    position: "absolute",
                    width: 30,
                    height: 30,
                    left: "50%",
                    top: "120%",
                    borderRadius: "100%",
                    fontSize: "18px",
                    fontWeight: "700",
                    backgroundColor: "#fa541b",
                    color: "black",

                    textAlign: "center",
                    alignContent: "center",
                    transform: "translate(-50%, 120%)",
                    border: "solid 2px #000f80",
                  }}
                >
                  3
                </Typography>
                <Typography
                  sx={{
                    position: "absolute",
                    width: "100px",
                    height: 30,
                    left: "50%",
                    top: "220%",
                    borderRadius: "100%",
                    fontSize: "13px",
                    fontWeight: 700,
                    color: "white",
                    textAlign: "center",
                    alignContent: "center",
                    transform: "translate(-50%, 220%)",
                  }}
                >
                  {parseInt(stateUser[2]?.money).toLocaleString("en-us")}
                </Typography>
              </Box>

              <Box></Box>
            </Box>
          </Box>
        </Box>
        <Box
          bgcolor={"#fafbff"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          p={4}
          gap={2}
          sx={{ overflowX: "hidden", overflowY: "scroll" }}
        >
          {stateUser &&
            stateUser.map((item, index) => (
              <Box
                p={"16px"}
                sx={{
                  backgroundColor: "white",
                  padding: "1rem",
                  borderRadius: "24px",
                  boxShadow: " 0 2px 6px rgba(0, 0, 0, 0.2)",
                  display: "flex",
                }}
                className="row"
              >
                <Box className="col-3" p={0}>
                  <Avatar
                    sx={{ width: 60, height: 60 }}
                    src={item?.avatar}
                  ></Avatar>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  className="col-9"
                >
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontSize: "14px",
                      color: "#1F1F1F",
                      fontWeight: "600",
                    }}
                  >
                    {item?.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#1F1F1F",
                    }}
                  >
                    Vừa có đơn hàng trong ngày, trị giá
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#FF6314",
                      fontWeight: "600",
                    }}
                  >
                    đ {parseInt(item?.money).toLocaleString("en-us")}
                  </Typography>
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
    </>
  );
};
export default Rank;
