import PANEL1 from "../assets/image/productPage/carousel1.jpg";
import PANEL2 from "../assets/image/productPage/carousel2.jpg";
import PANEL3 from "../assets/image/productPage/carousel3.jpg";

export const mockdataProduct = [
  {
    name: "Kem dưỡng da",
    description: `Kem dưỡng da được ví như "thức ăn" dành cho da, nó thẩm thấu và nuôi dưỡng da bạn từ sâu bên trong, da bạn sẽ trắng sáng, đều màu, các vết sạm nám, đồi mồi sẽ giảm đi theo thời gian. Chính vì thế nếu chăm chỉ sử dụng đều đặn, da bạn sẽ mịn màng, tăng độ đàn hồi và tươi trẻ hơn.`,
    image: [
      "https://thorakaocaugiay.com/wp-content/uploads/2020/11/Kem-duong-trang-da-sua-de-ngoc-trai-Thorakao-30g_tcg.jpg",
    ],
    price: 100000,
    discount: 20,
    amount: 120,
    Profit: 100000 * 0.06,
    point: 100,
    sold: 120567,
  },
  {
    id: "2",
    name: "Kem dưỡng ẩm",
    description: `Kem dưỡng ẩm chứa các hợp chất hóa học hỗ trợ bên ngoài để giữ nước và duy trì độ ẩm cần thiết cho da, hạn chế tình trạng da khô, bong tróc gây ra bởi lớp ngoài cùng của biểu bì da bị mất nước. Ngoài ra, sản phẩm cũng có tác dụng làm đều màu da, chống oxy hóa và chống lão hóa.`,
    image: [
      "https://bizweb.dktcdn.net/100/202/714/products/17-copy.jpg?v=1519812989540",
    ],
    price: 120000,
    discount: 40,
    amount: 200,
    Profit: 50000 * 0.06,
    point: 1004,
    sold: 120567,
  },
  {
    id: "3",
    name: "Son môi Revolution Lipstick màu Đỏ Đậm Cherry | Princess Beauty",
    description:
      "Son môi là mỹ phẩm có chứa sắc tố, dầu, sáp, chất làm mềm da; có tác dụng tô thoa tạo màu sắc, tạo bề mặt và bảo vệ đôi môi. Nhiều màu sắc và kiểu loại son môi tồn tại. Như với hầu hết nhiều loại mỹ phẩm, son môi khá phổ biến, nhưng không phải độc quyền dành cho phái nữ. Sử dụng son môi đã tồn tại từ thời Trung Cổ.",
    image: [
      "https://princesswhite.net/wp-content/uploads/2019/01/son-m%C3%94i-revolution-lipstck-cam-%C4%90%E1%BA%A5t-2.png",
    ],
    price: 145000,
    discount: 0,
    amount: 80,
    Profit: 145000 * 0.06,
    point: 100,
    sold: 120,
  },
  {
    id: "4",
    name: "Bút Kẻ Mắt Nước Siêu Sắc Mảnh, Không Lem Trôi Maybelline New York Hyper Sharp",
    description:
      "Bút kẻ mắt là một loại mỹ phẩm được sử dụng để định hình đôi mắt. Nó dùng để tô vẽ trên đường viền mắt nhằm tạo ra một loạt hiệu ứng thẩm mỹ.",
    image: [
      "https://product.hstatic.net/1000006063/product/4916_032ea5ce6c1741d480253c59d1b92a69_1024x1024.jpg",
    ],
    price: 16800000,
    discount: 45,
    amount: 120,
    Profit: 100000 * 0.06,
    point: 1200,
    sold: 2567,
  },
  {
    id: "5",
    name: "GƯƠNG TRÒN TREO TƯỜNG O- MIRROR CIRCLE D50 BLACK",
    description:
      "Gương tròn trang trí decor với mặt gương sáng và phần khung được làm bằng chất liệu PU bền đẹp, không thấm nước được thiết kế chắc chắn, không ...",
    image: [
      "https://product.hstatic.net/200000044142/product/guong_tron_treo_tuong_den_0321_5378ab248dfa4fecb9cfa846374ae92b_grande.jpg",
    ],
    price: 100000,
    discount: 20,
    amount: 120,
    Profit: 100000 * 0.06,
    point: 100,
    sold: 120567,
  },
];

export const MockDataIMG = [
  "https://inlachong.com/wp-content/uploads/2020/06/thiet-ke-logo-my-pham-dep-gia-re.jpg",
  "https://trungthanhprint.com/upload/logo-my-pham-11.jpg",
  "https://topprint.vn/wp-content/uploads/2022/08/top-20-mau-logo-my-pham-dep-thoi-thuong-7.jpg",
  "https://inhoangha.com/uploads/logo-starbucks.jpg",
  "https://www.chapi.vn/img/img/article/2021/hermes-paris-logo.png",
  "https://inlachong.com/wp-content/uploads/2020/06/thiet-ke-logo-my-pham-dep-gia-re.jpg",
  "https://trungthanhprint.com/upload/logo-my-pham-11.jpg",
  "https://topprint.vn/wp-content/uploads/2022/08/top-20-mau-logo-my-pham-dep-thoi-thuong-7.jpg",
];

export const DATAIMG_PANEL_PRODUCT = [
  "https://res.cloudinary.com/dhscgjuis/image/upload/v1727162105/TikpiiBIZ/Carousel/lowmz7qma5ikfqnp09xm.jpg  ",
  "https://res.cloudinary.com/dhscgjuis/image/upload/v1726199590/TikpiiBIZ/gwmgozavvcs3cpysofya.png",
  "https://res.cloudinary.com/dhscgjuis/image/upload/v1726199585/TikpiiBIZ/jdfuyx7tl7hymgwao5wj.png",
  "https://res.cloudinary.com/dhscgjuis/image/upload/v1726199585/TikpiiBIZ/odrcaaf4pzwpdvzoqxux.png",
  "https://res.cloudinary.com/dhscgjuis/image/upload/v1728122449/TikpiiMall/Carousel/xgcfxm1bqb9zs3iv90gt.jpg",
  "https://res.cloudinary.com/dhscgjuis/image/upload/v1727162119/TikpiiBIZ/Carousel/yg7u5iwvsucohnrlomt8.jpg",
  "https://res.cloudinary.com/dhscgjuis/image/upload/v1727162119/TikpiiBIZ/Carousel/edzbahnid6se2k1nezov.jpg",
  "https://res.cloudinary.com/dhscgjuis/image/upload/v1727162124/TikpiiBIZ/Carousel/atmxbqf47n6toapdt6nj.jpg",
  "https://res.cloudinary.com/dhscgjuis/image/upload/v1727162125/TikpiiBIZ/Carousel/ld5siwztvnsioytrjupc.jpg",
];

export const MOCK_DATA_USER = [
  {
    _id: { $oid: "66bc5272f4046f08d6a40cc5" },
    referrer_id: "",
    name: "Nguyễn Minh Khánh",
    email: "clone1@gmail.com",
    password:
      "76f4e713b7d66f7d0a7478694cee4ea4b39b19bd315715c5b5cda3337db32091",
    address:
      "Số 123, Đường Nguyễn Trãi, Phường Thanh Xuân Trung, Quận Thanh Xuân, Hà Nội",
    avatar:
      "https://res.cloudinary.com/dhscgjuis/image/upload/v1723618388/Tikpii/dc5d0ec6-c7c4-4c15-83aa-f00badf92a5c.jpg",
    phone: "0589907799",
    bank_info: null,
    date_of_birth: { $date: "2000-02-03T00:00:00.000Z" },
    aff_code: "",
    parent_aff_code: "",
    roles: 1,
    verify: 1,
    created_at: { $date: "2024-08-14T06:45:06.573Z" },
    updated_at: { $date: "2024-08-14T07:09:07.519Z" },
    money: 1116932,
  },
  {
    _id: { $oid: "66bc529ef4046f08d6a40cc7" },
    referrer_id: "",
    name: "Phạm Văn Nam",
    email: "clone8@gmail.com",
    password:
      "76f4e713b7d66f7d0a7478694cee4ea4b39b19bd315715c5b5cda3337db32091",
    address: "",
    avatar:
      "https://res.cloudinary.com/dhscgjuis/image/upload/v1723618449/Tikpii/5dfc7776-68d6-4b1f-9ec8-501b4ff20966.jpg",
    phone: "0123456789",
    bank_info: null,
    date_of_birth: { $date: "2024-08-08T00:00:00.000Z" },
    aff_code: "",
    parent_aff_code: "",
    roles: 1,
    verify: 1,
    created_at: { $date: "2024-08-14T06:45:50.745Z" },
    updated_at: { $date: "2024-08-14T07:09:07.426Z" },
    money: 323575,
  },
  {
    _id: { $oid: "66bc52abf4046f08d6a40cc8" },
    referrer_id: "",
    name: "Nguyễn Thị Lan",
    email: "clone16@gmail.com",
    password:
      "76f4e713b7d66f7d0a7478694cee4ea4b39b19bd315715c5b5cda3337db32091",
    address: "",
    avatar:
      "https://res.cloudinary.com/dhscgjuis/image/upload/v1723618871/Tikpii/4d83ef5a-9c34-425a-9110-5651257716d3.jpg",
    phone: "0934979797",
    bank_info: null,
    date_of_birth: { $date: "1988-02-05T00:00:00.000Z" },
    aff_code: "",
    parent_aff_code: "",
    roles: 1,
    verify: 1,
    created_at: { $date: "2024-08-14T06:46:03.047Z" },
    updated_at: { $date: "2024-08-14T07:09:07.720Z" },
    money: 1420400,
  },
  {
    _id: { $oid: "66bc52acf4046f08d6a40cc9" },
    referrer_id: "",
    name: "Trần Ngọc Anh\t",
    email: "clone2@gmail.com",
    password:
      "76f4e713b7d66f7d0a7478694cee4ea4b39b19bd315715c5b5cda3337db32091",
    address: "Số 567, Đường Lê Văn Sỹ, Phường 13, Quận 3, Hồ Chí Minh",
    avatar:
      "https://res.cloudinary.com/dhscgjuis/image/upload/v1723619272/Tikpii/6e420e3a-170d-483f-aa6d-6c0c54b0f3fd.jpg",
    phone: "0583067799",
    bank_info: null,
    date_of_birth: { $date: "1995-08-12T00:00:00.000Z" },
    aff_code: "",
    parent_aff_code: "",
    roles: 1,
    verify: 1,
    created_at: { $date: "2024-08-14T06:46:04.967Z" },
    updated_at: { $date: "2024-08-14T07:09:07.325Z" },
    money: 919482,
  },
  {
    _id: { $oid: "66bc52d8f4046f08d6a40cca" },
    referrer_id: "",
    name: "Lê Quang Huy\t",
    email: "clone3@gmail.com",
    password:
      "76f4e713b7d66f7d0a7478694cee4ea4b39b19bd315715c5b5cda3337db32091",
    address:
      "Số 890, Đường Hai Bà Trưng, Phường Bến Nghé, Quận 1, Hồ Chí Minh\t",
    avatar:
      "https://res.cloudinary.com/dhscgjuis/image/upload/v1723619430/Tikpii/7c9beb19-1b12-4289-b4ff-140d02431f94.jpg",
    phone: "0589207799",
    bank_info: null,
    date_of_birth: { $date: "1998-11-25T00:00:00.000Z" },
    aff_code: "",
    parent_aff_code: "",
    roles: 1,
    verify: 1,
    created_at: { $date: "2024-08-14T06:46:48.960Z" },
    updated_at: { $date: "2024-08-14T07:11:22.226Z" },
    money: 824276,
  },
  {
    _id: { $oid: "66bc52dcf4046f08d6a40ccb" },
    referrer_id: "",
    name: "Ngô Chí Trai",
    email: "clone9@gmail.com",
    password:
      "76f4e713b7d66f7d0a7478694cee4ea4b39b19bd315715c5b5cda3337db32091",
    address: "1 lê văn chí",
    avatar:
      "https://res.cloudinary.com/dhscgjuis/image/upload/v1723619121/Tikpii/60302fb8-e0ca-4bbb-af5a-c138fbdf8826.jpg",
    phone: "0123456789",
    bank_info: null,
    date_of_birth: { $date: "2024-07-03T00:00:00.000Z" },
    aff_code: "",
    parent_aff_code: "",
    roles: 1,
    verify: 1,
    created_at: { $date: "2024-08-14T06:46:52.975Z" },
    updated_at: { $date: "2024-08-14T07:14:48.005Z" },
    money: 529179,
  },
  {
    _id: { $oid: "66bc5300f4046f08d6a40ccc" },
    referrer_id: "",
    name: "Trần Chí Thanh",
    email: "clone10@gmail.com",
    password:
      "76f4e713b7d66f7d0a7478694cee4ea4b39b19bd315715c5b5cda3337db32091",
    address: "1 võ văn ngân",
    avatar:
      "https://res.cloudinary.com/dhscgjuis/image/upload/v1723619853/Tikpii/a06459c1-d043-495f-bafd-1a75a2caefd0.jpg",
    phone: "0123456789",
    bank_info: null,
    date_of_birth: { $date: "2024-08-14T06:47:28.873Z" },
    aff_code: "",
    parent_aff_code: "",
    roles: 1,
    verify: 1,
    created_at: { $date: "2024-08-14T06:47:28.873Z" },
    updated_at: { $date: "2024-08-14T07:18:25.932Z" },
    money: 186073,
  },
  {
    _id: { $oid: "66bc5305f4046f08d6a40ccd" },
    referrer_id: "",
    name: "Vũ Minh Quốc\t",
    email: "clone4@gmail.com",
    password:
      "76f4e713b7d66f7d0a7478694cee4ea4b39b19bd315715c5b5cda3337db32091",
    address: "Số 321, Đường Bà Triệu, Quận Hai Bà Trưng, Hà Nội\t",
    avatar:
      "https://res.cloudinary.com/dhscgjuis/image/upload/v1723619593/Tikpii/5275f720-b626-4dfd-9eee-94cafed532fb.jpg",
    phone: "0928997733",
    bank_info: null,
    date_of_birth: { $date: "1995-06-10T00:00:00.000Z" },
    aff_code: "",
    parent_aff_code: "",
    roles: 1,
    verify: 1,
    created_at: { $date: "2024-08-14T06:47:33.358Z" },
    updated_at: { $date: "2024-08-14T07:14:12.766Z" },
    money: 1300535,
  },
  {
    _id: { $oid: "66bc5312f4046f08d6a40cce" },
    referrer_id: "",
    name: "Nguyễn Thị Linh",
    email: "clone17@gmail.com",
    password:
      "76f4e713b7d66f7d0a7478694cee4ea4b39b19bd315715c5b5cda3337db32091",
    address: "",
    avatar:
      "https://res.cloudinary.com/dhscgjuis/image/upload/v1723619144/Tikpii/0a1b4ca2-1075-44ab-85af-f17a78210cdf.jpg",
    phone: "0937343434",
    bank_info: null,
    date_of_birth: { $date: "1997-07-04T00:00:00.000Z" },
    aff_code: "",
    parent_aff_code: "",
    roles: 1,
    verify: 1,
    created_at: { $date: "2024-08-14T06:47:46.292Z" },
    updated_at: { $date: "2024-08-14T07:09:07.720Z" },
    money: 1449453,
  },
  {
    _id: { $oid: "66bc5325f4046f08d6a40ccf" },
    referrer_id: "",
    name: "Trần Văn Sơn\t",
    email: "clone5@gmail.com",
    password:
      "76f4e713b7d66f7d0a7478694cee4ea4b39b19bd315715c5b5cda3337db32091",
    address: "Số 765, Đường Ba Tháng Hai, Quận 10, Hồ Chí Minh\t",
    avatar:
      "https://res.cloudinary.com/dhscgjuis/image/upload/v1723619858/Tikpii/6aa65590-678d-44d7-8f57-bd2addc60692.jpg",
    phone: "0584777733",
    bank_info: null,
    date_of_birth: { $date: "2001-02-21T00:00:00.000Z" },
    aff_code: "",
    parent_aff_code: "",
    roles: 1,
    verify: 1,
    created_at: { $date: "2024-08-14T06:48:05.742Z" },
    updated_at: { $date: "2024-08-14T07:18:13.673Z" },
    money: 319577,
  },
  {
    _id: { $oid: "66bc5327f4046f08d6a40cd0" },
    referrer_id: "",
    name: "Vũ Nhật Bình",
    email: "clone11@gmail.com",
    password:
      "76f4e713b7d66f7d0a7478694cee4ea4b39b19bd315715c5b5cda3337db32091",
    address: "1 tô vĩnh diện",
    avatar:
      "https://res.cloudinary.com/dhscgjuis/image/upload/v1723619999/Tikpii/357546c5-6d97-482a-9b95-5851791071f9.jpg",
    phone: "0123456789",
    bank_info: null,
    date_of_birth: { $date: "2024-08-02T00:00:00.000Z" },
    aff_code: "",
    parent_aff_code: "",
    roles: 1,
    verify: 1,
    created_at: { $date: "2024-08-14T06:48:07.244Z" },
    updated_at: { $date: "2024-08-14T07:20:35.967Z" },
    money: 686296,
  },
  {
    _id: { $oid: "66bc5343f4046f08d6a40cd1" },
    referrer_id: "",
    name: "Lê Bảo Thanh",
    email: "clone12@gmail.com",
    password:
      "76f4e713b7d66f7d0a7478694cee4ea4b39b19bd315715c5b5cda3337db32091",
    address: "1 Linh trung",
    avatar:
      "https://res.cloudinary.com/dhscgjuis/image/upload/v1723620280/Tikpii/8b7db276-3020-4faf-8208-03081abd22dc.jpg",
    phone: "0123456789",
    bank_info: null,
    date_of_birth: { $date: "2024-07-29T00:00:00.000Z" },
    aff_code: "",
    parent_aff_code: "",
    roles: 1,
    verify: 1,
    created_at: { $date: "2024-08-14T06:48:35.672Z" },
    updated_at: { $date: "2024-08-14T07:25:32.987Z" },
    money: 268949,
  },
  {
    _id: { $oid: "66bc534cf4046f08d6a40cd2" },
    referrer_id: "",
    name: "Nguyễn Thị Lộc",
    email: "clone18@gmail.com",
    password:
      "76f4e713b7d66f7d0a7478694cee4ea4b39b19bd315715c5b5cda3337db32091",
    address: "",
    avatar:
      "https://res.cloudinary.com/dhscgjuis/image/upload/v1723619297/Tikpii/026e4b24-1a56-4aea-995b-27df62cda517.jpg",
    phone: "0762343434",
    bank_info: null,
    date_of_birth: { $date: "2000-08-04T00:00:00.000Z" },
    aff_code: "",
    parent_aff_code: "",
    roles: 1,
    verify: 1,
    created_at: { $date: "2024-08-14T06:48:44.231Z" },
    updated_at: { $date: "2024-08-14T07:09:34.679Z" },
    money: 247003,
  },
  {
    _id: { $oid: "66bc5354f4046f08d6a40cd3" },
    referrer_id: "",
    name: "Đặng Xuân Trường\t",
    email: "clone6@gmail.com",
    password:
      "76f4e713b7d66f7d0a7478694cee4ea4b39b19bd315715c5b5cda3337db32091",
    address: "Số 654, Đường Tô Hiến Thành, Quận 10, Hồ Chí Minh\t",
    avatar:
      "https://res.cloudinary.com/dhscgjuis/image/upload/v1723620034/Tikpii/85319108-eaf1-46c4-b04e-160fbe2d37fc.jpg",
    phone: "0588066366",
    bank_info: null,
    date_of_birth: { $date: "1990-10-21T00:00:00.000Z" },
    aff_code: "",
    parent_aff_code: "",
    roles: 1,
    verify: 1,
    created_at: { $date: "2024-08-14T06:48:52.714Z" },
    updated_at: { $date: "2024-08-14T07:19:42.616Z" },
    money: 820226,
  },
  {
    _id: { $oid: "66bc5362f4046f08d6a40cd4" },
    referrer_id: "",
    name: "Nguyễn Nhật Trang",
    email: "clone13@gmail.com",
    password:
      "76f4e713b7d66f7d0a7478694cee4ea4b39b19bd315715c5b5cda3337db32091",
    address: "1 Hàn thuyên",
    avatar:
      "https://res.cloudinary.com/dhscgjuis/image/upload/v1723620401/Tikpii/4ae4d0c7-4eaa-4064-9f94-d6286d1ef5fc.jpg",
    phone: "0123456789",
    bank_info: null,
    date_of_birth: { $date: "2024-07-02T00:00:00.000Z" },
    aff_code: "",
    parent_aff_code: "",
    roles: 1,
    verify: 1,
    created_at: { $date: "2024-08-14T06:49:06.538Z" },
    updated_at: { $date: "2024-08-14T07:27:31.712Z" },
    money: 775256,
  },
  {
    _id: { $oid: "66bc5375f4046f08d6a40cd5" },
    referrer_id: "",
    name: "Hoàng Anh Tuấn\t",
    email: "clone7@gmail.com",
    password:
      "76f4e713b7d66f7d0a7478694cee4ea4b39b19bd315715c5b5cda3337db32091",
    address: "Số 789, Đường Láng, Quận Đống Đa, Hà Nội\t",
    avatar:
      "https://res.cloudinary.com/dhscgjuis/image/upload/v1723620112/Tikpii/f99318fe-7e39-45cd-9962-0c7952061b71.jpg",
    phone: "0927227700",
    bank_info: null,
    date_of_birth: { $date: "1999-06-08T00:00:00.000Z" },
    aff_code: "",
    parent_aff_code: "",
    roles: 1,
    verify: 1,
    created_at: { $date: "2024-08-14T06:49:25.763Z" },
    updated_at: { $date: "2024-08-14T07:23:00.703Z" },
    money: 1019608,
  },
  {
    _id: { $oid: "66bc5380f4046f08d6a40cd6" },
    referrer_id: "",
    name: "Nguyễn Văn Tài",
    email: "clone19@gmail.com",
    password:
      "76f4e713b7d66f7d0a7478694cee4ea4b39b19bd315715c5b5cda3337db32091",
    address: "",
    avatar:
      "https://res.cloudinary.com/dhscgjuis/image/upload/v1723619486/Tikpii/9080b6ed-15ef-4f97-9ff1-f94befe06a4a.jpg",
    phone: "0702584222",
    bank_info: null,
    date_of_birth: { $date: "2005-08-04T00:00:00.000Z" },
    aff_code: "",
    parent_aff_code: "",
    roles: 1,
    verify: 1,
    created_at: { $date: "2024-08-14T06:49:36.025Z" },
    updated_at: { $date: "2024-08-14T07:13:04.918Z" },
    money: 1075578,
  },
  {
    _id: { $oid: "66bc5380f4046f08d6a40cd7" },
    referrer_id: "",
    name: "Nguyễn Thị Lành",
    email: "clone14@gmail.com",
    password:
      "76f4e713b7d66f7d0a7478694cee4ea4b39b19bd315715c5b5cda3337db32091",
    address: "",
    avatar:
      "https://res.cloudinary.com/dhscgjuis/image/upload/v1723620545/Tikpii/59473d8a-30d4-45a0-8a13-cd278e803c77.jpg",
    phone: "0123456789",
    bank_info: null,
    date_of_birth: { $date: "1982-08-06T00:00:00.000Z" },
    aff_code: "",
    parent_aff_code: "",
    roles: 1,
    verify: 1,
    created_at: { $date: "2024-08-14T06:49:36.502Z" },
    updated_at: { $date: "2024-08-14T07:30:10.052Z" },
    money: 1419546,
  },
  {
    _id: { $oid: "66bc53a8f4046f08d6a40cdb" },
    referrer_id: "",
    name: "Nguyễn Thị Long",
    email: "clone15@gmail.com",
    password:
      "76f4e713b7d66f7d0a7478694cee4ea4b39b19bd315715c5b5cda3337db32091",
    address: "",
    avatar:
      "https://res.cloudinary.com/dhscgjuis/image/upload/v1723620350/Tikpii/790eefbb-ed67-42b0-9877-71c2de882fed.jpg",
    phone: "0123456789",
    bank_info: null,
    date_of_birth: { $date: "1999-08-06T00:00:00.000Z" },
    aff_code: "",
    parent_aff_code: "",
    roles: 1,
    verify: 1,
    created_at: { $date: "2024-08-14T06:50:16.088Z" },
    updated_at: { $date: "2024-08-14T07:26:40.634Z" },
    money: 815604,
  },
  {
    _id: { $oid: "66bc53aef4046f08d6a40cdd" },
    referrer_id: "",
    name: "Nguyễn Văn Sáng",
    email: "clone20@gmail.com",
    password:
      "76f4e713b7d66f7d0a7478694cee4ea4b39b19bd315715c5b5cda3337db32091",
    address: "",
    avatar:
      "https://res.cloudinary.com/dhscgjuis/image/upload/v1723619675/Tikpii/7e0d251c-cb24-4668-867d-91b501c3fe67.jpg",
    phone: "0704410789",
    bank_info: null,
    date_of_birth: { $date: "2000-08-02T00:00:00.000Z" },
    aff_code: "",
    parent_aff_code: "",
    roles: 1,
    verify: 1,
    created_at: { $date: "2024-08-14T06:50:22.839Z" },
    updated_at: { $date: "2024-08-14T07:15:46.242Z" },
    money: 600781,
  },
];
